/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.Module {
  height: calc(100vh - 60px);
}
.Module .module-header {
  background-color: #272e34;
  min-height: 62px;
  clear: both;
}
.Module .module-header .wrap {
  display: inline-flex;
  max-width: 100%;
  position: relative;
  top: 0px;
  left: 0px;
}
.Module .module-header .module-header-info {
  height: 62px;
}
.Module .module-header .module-header-info .module-header-banner {
  font-size: x-large;
  text-align: center;
}
.Module .module-header .module-header-info .module-header-title {
  text-align: center;
}
.Module .module-header .module-header-info .module-header-title i,
.Module .module-header .module-header-info .module-header-title a {
  color: #0df;
}
.Module .module-header .module-header-info .module-header-title a {
  font-size: 1rem;
}
.Module .module-header .module-header-info .module-header-title i {
  transform: scale(0.9);
}
.Module .module-header .header-selected {
  border-radius: 0;
}
.Module .module-header .view-exit {
  float: left;
  margin-top: 10px;
  margin-left: 10px;
}
.Module .module-header .view-title {
  float: left;
  padding-top: 10px;
  padding-left: 10px;
}
.Module .module-header .view-title .material-icons {
  color: #fff;
  font-size: 24px;
  padding-left: 20px;
}
.Module .module-header .header-bar {
  float: left;
  margin-left: 10px;
}
.Module .module-header .header-bar a .btn {
  margin-right: 10px;
}
.Module .module-header .header-bar .form-inline {
  float: left;
  padding-top: 11px;
  font-size: 1rem;
}
.Module .module-header .header-bar .form-inline .input-group {
  margin-right: 20px;
}
.Module .module-header .header-bar .form-inline input {
  display: inline-block;
}
.Module .module-header .header-bar .form-inline label {
  padding-right: 5px;
  text-transform: capitalize;
}
.Module .module-header .header-bar .form-inline ::placeholder {
  color: #dae0e7;
}
.Module .module-header .secondary-header-toolbar {
  float: right;
  margin-right: 10px;
}
.Module .module-header .header-menu {
  background-color: #272e34;
}
.Module .module-header .header-selector {
  padding: 10px;
}
.Module .module-header .header-selector label {
  padding-top: 10px;
  padding-right: 10px;
  margin: 0;
}
.Module .module-header .header-selector .material-icons {
  padding-top: 5px;
}
.Module .module-header .header-selector .dropdown-menu-dark button {
  color: #aaaeb3;
  background-color: #272e34;
}
.Module .module-header .header-disabled {
  visibility: hidden;
}
.Module .module-header .btn-toolbar .header-button-group .material-icons {
  position: relative;
  top: 2px;
  color: #aaaeb3;
  padding-right: 5px;
  font-size: 16px;
}
.Module .module-header .btn-toolbar .header-button-group .selected .material-icons {
  color: #4bb3d8;
}
.Module .module-header .btn-toolbar .btn-group {
  margin-right: 10px;
}
.Module .module-header .btn-toolbar .btn-group .btn-secondary {
  outline: none;
}
.Module .module-header .btn-toolbar .btn-group .btn-secondary:focus {
  outline: none;
  box-shadow: none;
}
.Module .module-header .btn-toolbar .btn-group .btn {
  margin-right: 0;
}
.Module .module-header .btn-toolbar .date-range-group {
  margin-right: 0;
}
.Module .module-header .btn-toolbar .date-range-group .selected {
  color: #fff;
  background-color: #4bb3d8;
}
.Module .module-header .btn-toolbar .date-range-group .selected .material-icons {
  color: #fff;
}
.Module .module-content {
  margin-left: 10px;
  margin-right: 10px;
  background-color: #272e34;
  height: calc(100% - 62px);
}
.Module .module-content .header-id {
  font-size: small;
  color: #aaaeb3;
  padding-left: 10px;
}
.Module .module-content .content-view {
  height: 100%;
}
.Module .module-content .force {
  position: fixed;
  bottom: 0;
  margin-right: 20px;
  width: calc(100vw - 190px - 10px);
}
.Module .module-content .content-view-controls {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 0;
  padding-left: 0;
  height: 50px;
  background-color: #272e34;
}
.Module .module-content .content-view-controls a .btn:last-child {
  margin-right: 10px;
}
.Module .module-content .content-view-controls .header-button-group .btn {
  margin: 0;
}
.Module .module-content .content-view-controls label {
  margin-top: 5px;
  margin-right: 5px;
}
.Module .module-content .content-view-controls form {
  margin-right: 10px;
}
.Module .module-content .content-view-controls form .form-check-label-color {
  border-radius: 10px;
  padding: 0.25rem 0.5rem;
  margin-top: 0;
}
.Module .module-content .content-view-controls .form-check-inline {
  margin-right: 0;
}
.Module .module-content .search-bar {
  position: fixed;
  width: calc(100% - 190px);
  padding-right: 20px;
}
.Module .module-content .search-bar .input-group {
  padding-right: 5px;
}
.Module .module-content .search-bar .input-group .input-group-text {
  background-color: #272e34;
  color: #fff;
}
.Module .module-content .search-bar .input-group button,
.Module .module-content .search-bar .input-group input {
  background-color: #1f1f1f;
  color: #fff;
  border-color: #fff;
}
.Module .module-content .search-bar .input-group-sm {
  padding-right: 5px;
}
.Module .module-content .search-bar .input-group-sm .input-group-text {
  background-color: #272e34;
  color: #fff;
  border-radius: 10px;
  padding-inline: 0.5rem;
  padding-block: 0.25rem;
  font-size: 14px;
}
.Module .module-content .search-bar .input-group-sm button,
.Module .module-content .search-bar .input-group-sm input {
  background-color: #1f1f1f;
  color: #fff;
  border-color: #fff;
  border-radius: 10px;
  padding-inline: 0.5rem;
  padding-block: 0.25rem;
  font-size: 14px;
}
.Module .module-content .search-bar .form-check-label {
  padding: 0 5px;
  margin-right: 10px;
  border-radius: 5px;
}
.Module .module-content .content {
  float: left;
  width: calc(100% - 330px - 10px - 2px);
  height: 100%;
  background-color: #353c44;
}
.Module .module-content .content .content-with-title {
  height: 100%;
  border-bottom: solid 1px #272e34;
  overflow: auto;
}
.Module .module-content .content .content-main {
  height: calc(100% - 300px);
  border-bottom: solid 1px #272e34;
  overflow: auto;
}
.Module .module-content .content .content-main-full {
  height: 100%;
}
.Module .module-content .content .content-footer {
  height: 300px;
  width: 100%;
}
.Module .module-content .content .ok {
  background-color: #4caf50;
}
.Module .module-content .content .warning {
  background-color: orange;
}
.Module .module-content .content .error {
  background-color: #e22006;
}
.Module .module-content .content .no-status {
  background-color: #272e34;
}
.Module .module-content .content .no-match {
  text-align: center;
}
.Module .module-content .content .content-title {
  text-align: center;
  padding: 10px;
  margin-bottom: 0;
}
.Module .module-content .content .content-subtitle {
  text-align: center;
  padding: 10px;
  margin: 0;
}
.Module .module-content .content .site-content {
  padding-bottom: 50px;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.ok {
  color: #4caf50;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.warning {
  color: orange;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.error {
  color: #e22006;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.no-status {
  color: #fff;
}
.Module .module-content .content .site-content td.ranged {
  background-color: #272e34;
}
.Module .module-content .content .system-content {
  clear: both;
}
.Module .module-content .content table {
  width: 100%;
  border-collapse: separate;
  /* Don't collapse as this makes sticky headers see through */
  border-spacing: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.Module .module-content .content table th {
  top: 0;
  border-bottom: 2px solid #272e34;
  background-color: #353c44;
  z-index: 1;
  color: #4bb3d8;
  text-align: left;
}
.Module .module-content .content table th.ranged {
  color: #fff;
  background-color: #4bb3d8;
}
.Module .module-content .content table td {
  border: solid 1px #272e34;
}
.Module .module-content .content table td a {
  color: #fff;
}
.Module .module-content .content table td .btn-link {
  padding: 0;
  padding-left: 5px;
}
.Module .module-content .content table td .btn-link .material-icons {
  color: #4bb3d8;
}
.Module .module-content .content table td.danger {
  background-color: #e22006;
}
.Module .module-content .content table td.ranged {
  background-color: #353c44;
}
.Module .module-content .content table th.edit {
  width: 20px;
}
.Module .module-content .content .content-map {
  height: 100%;
  width: 100%;
}
.Module .module-content .content nav {
  background-color: #272e34;
  z-index: 100;
  color: #fff;
}
.Module .module-content .content nav .react-contextmenu-item {
  color: #fff;
}
.Module .module-content .content nav .ok > .react-contextmenu-item {
  background-color: #4caf50;
}
.Module .module-content .content nav .warning > .react-contextmenu-item {
  background-color: orange;
}
.Module .module-content .content nav .error > .react-contextmenu-item {
  background-color: #e22006;
}
.Module .module-content .content nav .no-nodes > .react-contextmenu-item {
  padding: 0 10px;
}
.Module .module-content .content .content-graph-menu {
  text-align: center;
  background-color: #272e34;
  clear: both;
}
.Module .module-content .content .content-graph-menu .content-graph-menu-left {
  font-weight: bold;
  float: left;
  margin-left: 10px;
}
.Module .module-content .content .content-graph-menu .content-graph-menu-right {
  float: right;
  margin-right: 10px;
}
.Module .module-content .content .content-graph-menu .dropdown {
  display: inline;
  z-index: 10;
}
.Module .module-content .content .content-graph-menu .dropdown .dropdown-menu {
  background-color: #1f1f1f;
}
.Module .module-content .content .content-graph-menu .dropdown button {
  color: #fff;
  background-color: #272e34;
  border: none;
}
.Module .module-content .content .content-graph-menu .dropdown .dropdown-item:hover {
  color: #fff;
  background-color: #4bb3d8;
}
.Module .module-content .content .content-graph-menu .dropdown .dropdown-toggle {
  color: #fff;
  box-shadow: none;
}
.Module .module-content .content .content-graph-menu .dropdown .selected {
  color: #4bb3d8;
}
.Module .module-content .content .content-graph-empty {
  text-align: center;
  font-size: large;
  padding-top: 100px;
}
.Module .module-content .content .content-graph,
.Module .module-content .content .content-source-graph {
  width: 100%;
}
.Module .module-content .content .content-graph .recharts-tooltip-wrapper,
.Module .module-content .content .content-source-graph .recharts-tooltip-wrapper {
  pointer-events: auto;
  margin-left: -5px;
}
.Module .module-content .content .content-graph .recharts-tooltip-wrapper .recharts-default-tooltip,
.Module .module-content .content .content-source-graph .recharts-tooltip-wrapper .recharts-default-tooltip {
  overflow-y: auto;
  overflow-x: hidden;
}
.Module .module-content .content .content-graph .recharts-tooltip-wrapper .recharts-default-tooltip .recharts-tooltip-item-list,
.Module .module-content .content .content-source-graph .recharts-tooltip-wrapper .recharts-default-tooltip .recharts-tooltip-item-list {
  max-height: 165px;
}
.Module .module-content .content .content-graph {
  height: calc(300px - 30px);
}
.Module .module-content .content .content-source-graph {
  height: calc(300px + 230px);
}
.Module .module-content .content-full {
  width: 100%;
  overflow-y: auto;
}
.Module .module-content .content-full-with-title {
  width: 100%;
  height: calc(100% - 66px);
  overflow-y: auto;
}
.Module .module-content .close-button {
  top: 0;
  font-size: 48px;
}
.Module .module-content .item-details {
  height: 100%;
}
.Module .module-content .item-details .item-details-title {
  padding: 10px 20px;
  font-size: large;
}
.Module .module-content .item-details .item-details-title h2 {
  padding: 0;
}
.Module .module-content .item-details .item-details-title h2 .right-title {
  float: right;
  font-size: medium;
  padding-top: 14px;
}
.Module .module-content .item-details .item-create-title {
  background-color: #4bb3d8;
}
.Module .module-content .item-details .item-details-content {
  overflow: auto;
  height: calc(100% - 66px);
  background-color: #272e34;
  padding: 20px;
}
.Module .module-content .item-details .item-details-content label {
  color: #4bb3d8;
  margin-right: 5px;
}
.Module .module-content .item-details .item-details-content .ecogy-form label {
  margin-right: 0;
}
.Module .module-content .item-details .item-details-content .type {
  text-transform: capitalize;
}
.Module .module-content .item-details .item-details-content hr {
  margin: 2px;
  border-top-color: #dae0e7;
}
.Module .module-content .item-details .item-details-content .remove-button {
  margin-bottom: 20px;
}
.Module .module-content .item-details .item-details-content form > .form-group fieldset > .form-group {
  width: 50%;
  padding-right: 10px;
}
.Module .module-content .item-details .item-details-content form > .form-group > div > div > fieldset > .form-group {
  width: 50%;
  padding-right: 10px;
}
.Module .module-content .item-details .item-details-content .right-column {
  float: right;
  clear: both;
  padding-right: 0;
  padding-left: 10px;
}
.Module .module-content .content-sidebar {
  margin-left: 10px;
  float: left;
  width: 330px;
  height: 100%;
  overflow: auto;
}
.Module .module-content .content-sidebar .sidebar-section {
  margin-top: 10px;
  background-color: #353c44;
  width: 100%;
  padding: 10px;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-title-wrapper {
  display: flex;
  justify-content: space-between;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-title {
  width: 100%;
  color: #fff;
  text-transform: uppercase;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-subtitle {
  color: #aaaeb3;
  float: right;
  margin: 0;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-range {
  color: #aaaeb3;
  margin: 0;
  font-size: small;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content {
  width: 100%;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .sidebar-heading {
  font-size: xx-large;
  font-weight: bold;
  color: #4bb3d8;
  text-align: center;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .positive {
  color: #00c4a0;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .negative {
  color: #ff9b91;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .sidebar-text {
  text-align: center;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .sidebar-text .material-icons {
  float: right;
  padding-top: 10px;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .siderbar-reading {
  color: #4bb3d8;
  font-size: x-large;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .warning {
  color: orange;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .siderbar-error {
  color: #4bb3d8;
  font-size: large;
  text-align: center;
  padding: 10px 0;
}
.Module .module-content .content-sidebar :first-child {
  margin: 0;
}
.Module .module-content .content-sidebar .date-range {
  color: #aaaeb3;
}
.Module .module-content .content-sidebar .date-range .start-date {
  color: #fff;
}
.Module .module-content .content-sidebar .date-range .end-date {
  color: #fff;
}
.Module .module-content .content-sidebar .date-range .date-label {
  width: 50px;
}
.Module .module-content .recharts-tooltip-cursor {
  fill-opacity: 25%;
}
.Module .module-content .content-with-controls {
  height: calc(100% - 50px);
}
.Module .collapsed {
  height: calc(100% - 62px - 41px);
}

.App-portfolio .module-header {
  background-color: #4bb3d8;
  min-height: 61px;
  border-bottom: 1px solid black;
}
.App-portfolio .module-content {
  margin-top: 10px;
  height: calc(100% - 62px - 10px);
}
.App-portfolio .collapsed {
  height: calc(100% - 62px - 41px - 10px);
}

.area-graph-tooltip {
  background: #fff;
  padding: 10px;
  color: #272e34;
  text-transform: capitalize;
}
.area-graph-tooltip p {
  margin: 0;
}

.econode-tooltip .area-graph-tooltip,
.ecogy-tooltip .area-graph-tooltip {
  text-transform: none;
}

.modal {
  color: #000;
}
.modal .error {
  color: #e22006;
}

.custom-range-button {
  padding-top: 0;
  padding-bottom: 0;
}

.custom-range-name > * {
  float: left;
}
.custom-range-name .range .range-start {
  font-size: small;
}
.custom-range-name .range .range-end {
  font-size: small;
}
.custom-range-name .range .range-divider {
  display: none;
}

.date-range-menu {
  width: 700px;
  height: 370px;
  left: auto;
  right: 0;
}
.date-range-menu .custom-date-range {
  float: left;
  width: 510px;
}
.date-range-menu .custom-date-range .custom-date {
  float: left;
  width: 250px;
  margin-left: 5px;
  height: 310px;
}
.date-range-menu .precanned-date-ranges {
  float: left;
  margin-left: 10px;
  height: 100%;
  width: 170px;
  background-color: #353c44;
  border-radius: 5px;
  padding: 10px;
}
.date-range-menu .precanned-date-ranges .dropdown-item {
  padding: 0.25rem;
  border: none;
}
.date-range-menu .precanned-date-ranges .selected {
  color: #fff;
  background-color: #4bb3d8;
}
.date-range-menu .date-range-buttons {
  clear: both;
}
.date-range-menu .date-range-buttons .date-range-button {
  border-radius: 10px;
  float: right;
}
.date-range-menu .date-range-message {
  width: 100%;
  color: #4bb3d8;
  clear: both;
  padding: 8px 8px 0;
}
.date-range-menu .date-range-message .material-icons {
  color: #4bb3d8;
}
.date-range-menu .date-range-warning {
  width: 100%;
  color: #e22006;
  clear: both;
  padding: 8px 8px 0;
}
.date-range-menu .date-range-warning .material-icons {
  color: #e22006;
}

.react-select__option {
  color: rgb(29, 33, 39) !important;
}

.battery-view-header {
  text-align: center;
}

.battery-box {
  height: 150px;
  width: 200px;
  background-color: rgb(29, 33, 39);
  border-radius: 20px;
  display: grid;
  grid-template-columns: 75px 75px;
  color: grey;
}

.battery-container {
  text-align: center;
  align-items: center;
  justify-items: center;
  height: 350px;
  width: 250px;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 80px 230px;
  grid-template-rows: 80px 150px 80px;
  color: rgb(207, 205, 205);
}
.battery-container p {
  font-size: medium;
}
.battery-container .state-of-health {
  margin-top: 8px;
  margin-bottom: 40px;
  background-color: #193138;
  border-radius: 5px;
  font-size: small;
}

.graph-tail-end {
  height: 8px;
  width: 8px;
  background-color: #00C49F;
  bottom: 180px;
  left: 0px;
  position: absolute;
  border-radius: 5px;
  z-index: 100;
}

.battery-cell {
  margin-right: 10px;
  margin-left: 10px;
  background-color: #1c3841;
  height: 100px;
  width: 70px;
  position: relative;
}
.battery-cell .logo {
  position: relative;
  top: 40px;
  left: -14px;
  max-width: 100px;
  filter: opacity(0.6) drop-shadow(0 0 0 rgb(0, 0, 0));
  transform: rotate(270deg);
}
.battery-cell .charge-bar {
  margin: 5px;
  position: absolute;
  width: 60px;
  bottom: 0px;
  background-color: #4caf50;
}
.battery-cell .empty {
  visibility: hidden;
}
.battery-cell .low {
  background-color: #e22006;
}
.battery-cell .high {
  background-color: #4caf50;
}
.battery-cell .charge-bar-wrapper {
  position: absolute;
  height: 90px;
  width: 60px;
  bottom: 0px;
}
.battery-cell .charge-bar-top {
  clip-path: ellipse(30px 10px);
  height: 20px;
  position: absolute;
  width: 60px;
  top: -10px;
  box-shadow: inset 0px -6px 12px 0px hsla(0, 0%, 0%, 0.2);
}
.battery-cell .charge-bar-bottom {
  clip-path: ellipse(30px 10px);
  height: 20px;
  position: absolute;
  width: 60px;
  bottom: -10px;
}
.battery-cell .battery-cell-top {
  clip-path: ellipse(35px 10px);
  height: 20px;
  position: absolute;
  width: 70px;
  top: -10px;
  background-color: #193138;
  z-index: 100;
}
.battery-cell .battery-cell-top2 {
  clip-path: ellipse(35px 10px);
  height: 20px;
  position: absolute;
  width: 70px;
  top: -5px;
  background-color: #1c3841;
  z-index: 99;
}
.battery-cell .battery-cell-bottom {
  clip-path: ellipse(35px 10px);
  height: 20px;
  position: absolute;
  width: 70px;
  bottom: -10px;
  background-color: #1c3841;
}

.side-by-side {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

[class|=confetti] {
  position: absolute;
  z-index: 3000;
}

.papers-origin {
  position: absolute;
  overflow: visible;
  top: 50%;
  left: 50%;
}

.confetti-0 {
  width: 7px;
  height: 2.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 1px;
  opacity: 0;
  transform: rotate(13.6987875999deg);
  animation: drop-0 3.8610020076s 0.3802599556s 3;
}

@keyframes drop-0 {
  25% {
    top: -21vh;
    left: 1vw;
    opacity: 1.2222199354;
  }
  100% {
    top: 50vh;
    left: -10vw;
    opacity: 0;
  }
}
.confetti-1 {
  width: 3px;
  height: 1.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 0px;
  opacity: 0;
  transform: rotate(238.6207596048deg);
  animation: drop-1 3.6338111717s 0.3113749346s 3;
}

@keyframes drop-1 {
  25% {
    top: -23vh;
    left: -13vw;
    opacity: 1.0267133024;
  }
  100% {
    top: 50vh;
    left: -16vw;
    opacity: 0;
  }
}
.confetti-2 {
  width: 5px;
  height: 2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(252.1151819266deg);
  animation: drop-2 3.9705120205s 0.8559760526s 3;
}

@keyframes drop-2 {
  25% {
    top: -24vh;
    left: -3vw;
    opacity: 1.2826205934;
  }
  100% {
    top: 50vh;
    left: -11vw;
    opacity: 0;
  }
}
.confetti-3 {
  width: 5px;
  height: 2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 7px;
  opacity: 0;
  transform: rotate(160.5760292459deg);
  animation: drop-3 3.9731450311s 0.0004764251s 3;
}

@keyframes drop-3 {
  25% {
    top: -2vh;
    left: 4vw;
    opacity: 1.055528652;
  }
  100% {
    top: 50vh;
    left: 1vw;
    opacity: 0;
  }
}
.confetti-4 {
  width: 4px;
  height: 1.6px;
  background-color: #0483f2;
  top: 0px;
  left: 12px;
  opacity: 0;
  transform: rotate(289.502699641deg);
  animation: drop-4 3.9458235336s 0.144649995s 3;
}

@keyframes drop-4 {
  25% {
    top: -9vh;
    left: -5vw;
    opacity: 0.9814384434;
  }
  100% {
    top: 50vh;
    left: -16vw;
    opacity: 0;
  }
}
.confetti-5 {
  width: 7px;
  height: 2.8px;
  background-color: #0483f2;
  top: 0px;
  left: -13px;
  opacity: 0;
  transform: rotate(285.5816383895deg);
  animation: drop-5 3.4800511292s 0.6592570661s 3;
}

@keyframes drop-5 {
  25% {
    top: -17vh;
    left: -14vw;
    opacity: 0.6742195489;
  }
  100% {
    top: 50vh;
    left: -14vw;
    opacity: 0;
  }
}
.confetti-6 {
  width: 8px;
  height: 3.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 12px;
  opacity: 0;
  transform: rotate(237.0559005485deg);
  animation: drop-6 3.6828325872s 0.7699749187s 3;
}

@keyframes drop-6 {
  25% {
    top: -2vh;
    left: -2vw;
    opacity: 1.2227910587;
  }
  100% {
    top: 50vh;
    left: -11vw;
    opacity: 0;
  }
}
.confetti-7 {
  width: 5px;
  height: 2px;
  background-color: #42f212;
  top: 0px;
  left: 12px;
  opacity: 0;
  transform: rotate(20.7273371515deg);
  animation: drop-7 3.4820376148s 0.2066213196s 3;
}

@keyframes drop-7 {
  25% {
    top: -10vh;
    left: -8vw;
    opacity: 0.7713400333;
  }
  100% {
    top: 50vh;
    left: -13vw;
    opacity: 0;
  }
}
.confetti-8 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 13px;
  opacity: 0;
  transform: rotate(253.6899277474deg);
  animation: drop-8 3.8931407597s 0.3871684316s 3;
}

@keyframes drop-8 {
  25% {
    top: -8vh;
    left: 11vw;
    opacity: 0.7051643991;
  }
  100% {
    top: 50vh;
    left: 18vw;
    opacity: 0;
  }
}
.confetti-9 {
  width: 3px;
  height: 1.2px;
  background-color: #42f212;
  top: 0px;
  left: 4px;
  opacity: 0;
  transform: rotate(114.247622773deg);
  animation: drop-9 3.1130227852s 0.5007891422s 3;
}

@keyframes drop-9 {
  25% {
    top: -8vh;
    left: 11vw;
    opacity: 0.7106943879;
  }
  100% {
    top: 50vh;
    left: 23vw;
    opacity: 0;
  }
}
.confetti-10 {
  width: 8px;
  height: 3.2px;
  background-color: #42f212;
  top: 0px;
  left: -4px;
  opacity: 0;
  transform: rotate(242.0576806138deg);
  animation: drop-10 3.7105741803s 0.5890223454s 3;
}

@keyframes drop-10 {
  25% {
    top: -15vh;
    left: -8vw;
    opacity: 0.8458434286;
  }
  100% {
    top: 50vh;
    left: -10vw;
    opacity: 0;
  }
}
.confetti-11 {
  width: 1px;
  height: 0.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -6px;
  opacity: 0;
  transform: rotate(314.8371396866deg);
  animation: drop-11 3.2563605127s 0.8932259655s 3;
}

@keyframes drop-11 {
  25% {
    top: -24vh;
    left: -11vw;
    opacity: 0.702942951;
  }
  100% {
    top: 50vh;
    left: -24vw;
    opacity: 0;
  }
}
.confetti-12 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: 12px;
  opacity: 0;
  transform: rotate(140.7544531579deg);
  animation: drop-12 3.6355866346s 0.4296595656s 3;
}

@keyframes drop-12 {
  25% {
    top: -15vh;
    left: 13vw;
    opacity: 0.5424501236;
  }
  100% {
    top: 50vh;
    left: 7vw;
    opacity: 0;
  }
}
.confetti-13 {
  width: 6px;
  height: 2.4px;
  background-color: #42f212;
  top: 0px;
  left: 12px;
  opacity: 0;
  transform: rotate(9.4489623533deg);
  animation: drop-13 3.9585930216s 0.1106268091s 3;
}

@keyframes drop-13 {
  25% {
    top: -10vh;
    left: 10vw;
    opacity: 0.7089849384;
  }
  100% {
    top: 50vh;
    left: 11vw;
    opacity: 0;
  }
}
.confetti-14 {
  width: 8px;
  height: 3.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 7px;
  opacity: 0;
  transform: rotate(59.9212842641deg);
  animation: drop-14 3.0794842859s 0.9246749666s 3;
}

@keyframes drop-14 {
  25% {
    top: -18vh;
    left: -13vw;
    opacity: 1.318365267;
  }
  100% {
    top: 50vh;
    left: -17vw;
    opacity: 0;
  }
}
.confetti-15 {
  width: 4px;
  height: 1.6px;
  background-color: #42f212;
  top: 0px;
  left: 10px;
  opacity: 0;
  transform: rotate(23.1965575421deg);
  animation: drop-15 3.8983345294s 0.4456976092s 3;
}

@keyframes drop-15 {
  25% {
    top: -4vh;
    left: 10vw;
    opacity: 1.4978382662;
  }
  100% {
    top: 50vh;
    left: 9vw;
    opacity: 0;
  }
}
.confetti-16 {
  width: 8px;
  height: 3.2px;
  background-color: #42f212;
  top: 0px;
  left: 2px;
  opacity: 0;
  transform: rotate(93.3564906985deg);
  animation: drop-16 3.1861473309s 0.1247184808s 3;
}

@keyframes drop-16 {
  25% {
    top: -21vh;
    left: 0vw;
    opacity: 0.8919217333;
  }
  100% {
    top: 50vh;
    left: -1vw;
    opacity: 0;
  }
}
.confetti-17 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: 9px;
  opacity: 0;
  transform: rotate(218.3798915415deg);
  animation: drop-17 3.897088496s 0.9742438232s 3;
}

@keyframes drop-17 {
  25% {
    top: -19vh;
    left: 8vw;
    opacity: 1.1506849081;
  }
  100% {
    top: 50vh;
    left: 15vw;
    opacity: 0;
  }
}
.confetti-18 {
  width: 8px;
  height: 3.2px;
  background-color: #42f212;
  top: 0px;
  left: -5px;
  opacity: 0;
  transform: rotate(80.108368829deg);
  animation: drop-18 3.3683212052s 0.6856609328s 3;
}

@keyframes drop-18 {
  25% {
    top: -3vh;
    left: 7vw;
    opacity: 1.0817034474;
  }
  100% {
    top: 50vh;
    left: 10vw;
    opacity: 0;
  }
}
.confetti-19 {
  width: 7px;
  height: 2.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -14px;
  opacity: 0;
  transform: rotate(105.532904169deg);
  animation: drop-19 3.9629223023s 0.1769540984s 3;
}

@keyframes drop-19 {
  25% {
    top: -17vh;
    left: 0vw;
    opacity: 0.8144555694;
  }
  100% {
    top: 50vh;
    left: -7vw;
    opacity: 0;
  }
}
.confetti-20 {
  width: 6px;
  height: 2.4px;
  background-color: #42f212;
  top: 0px;
  left: -12px;
  opacity: 0;
  transform: rotate(171.2664775244deg);
  animation: drop-20 3.7406234529s 0.8073619655s 3;
}

@keyframes drop-20 {
  25% {
    top: -9vh;
    left: 6vw;
    opacity: 1.2076800311;
  }
  100% {
    top: 50vh;
    left: 3vw;
    opacity: 0;
  }
}
.confetti-21 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(120.4640153781deg);
  animation: drop-21 3.9551845881s 0.7107367613s 3;
}

@keyframes drop-21 {
  25% {
    top: -24vh;
    left: 5vw;
    opacity: 1.116592861;
  }
  100% {
    top: 50vh;
    left: 5vw;
    opacity: 0;
  }
}
.confetti-22 {
  width: 2px;
  height: 0.8px;
  background-color: #0483f2;
  top: 0px;
  left: 13px;
  opacity: 0;
  transform: rotate(99.7296512782deg);
  animation: drop-22 3.1260791473s 0.9677530011s 3;
}

@keyframes drop-22 {
  25% {
    top: -14vh;
    left: -12vw;
    opacity: 1.1622330213;
  }
  100% {
    top: 50vh;
    left: -5vw;
    opacity: 0;
  }
}
.confetti-23 {
  width: 3px;
  height: 1.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -5px;
  opacity: 0;
  transform: rotate(147.2332462842deg);
  animation: drop-23 3.1373475917s 0.3457012269s 3;
}

@keyframes drop-23 {
  25% {
    top: -8vh;
    left: 1vw;
    opacity: 0.904428333;
  }
  100% {
    top: 50vh;
    left: -9vw;
    opacity: 0;
  }
}
.confetti-24 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -2px;
  opacity: 0;
  transform: rotate(319.0682214064deg);
  animation: drop-24 3.0142801449s 0.1860379944s 3;
}

@keyframes drop-24 {
  25% {
    top: -13vh;
    left: -1vw;
    opacity: 0.6344389716;
  }
  100% {
    top: 50vh;
    left: -14vw;
    opacity: 0;
  }
}
.confetti-25 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: -13px;
  opacity: 0;
  transform: rotate(66.8183247686deg);
  animation: drop-25 3.3500788805s 0.8220237067s 3;
}

@keyframes drop-25 {
  25% {
    top: -10vh;
    left: 1vw;
    opacity: 0.6278465968;
  }
  100% {
    top: 50vh;
    left: -9vw;
    opacity: 0;
  }
}
.confetti-26 {
  width: 4px;
  height: 1.6px;
  background-color: #42f212;
  top: 0px;
  left: -9px;
  opacity: 0;
  transform: rotate(291.5246492345deg);
  animation: drop-26 3.6435977645s 0.3268014454s 3;
}

@keyframes drop-26 {
  25% {
    top: -12vh;
    left: 0vw;
    opacity: 1.3002519375;
  }
  100% {
    top: 50vh;
    left: -1vw;
    opacity: 0;
  }
}
.confetti-27 {
  width: 3px;
  height: 1.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -3px;
  opacity: 0;
  transform: rotate(174.5759968009deg);
  animation: drop-27 3.8012794066s 0.0789706284s 3;
}

@keyframes drop-27 {
  25% {
    top: -8vh;
    left: -1vw;
    opacity: 0.5252085012;
  }
  100% {
    top: 50vh;
    left: 8vw;
    opacity: 0;
  }
}
.confetti-28 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: -1px;
  opacity: 0;
  transform: rotate(210.754822716deg);
  animation: drop-28 3.0012105555s 0.5889890209s 3;
}

@keyframes drop-28 {
  25% {
    top: -19vh;
    left: 5vw;
    opacity: 1.0600385151;
  }
  100% {
    top: 50vh;
    left: 17vw;
    opacity: 0;
  }
}
.confetti-29 {
  width: 6px;
  height: 2.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -7px;
  opacity: 0;
  transform: rotate(159.9769330336deg);
  animation: drop-29 3.187884331s 0.9233926152s 3;
}

@keyframes drop-29 {
  25% {
    top: -8vh;
    left: -13vw;
    opacity: 1.3678744526;
  }
  100% {
    top: 50vh;
    left: -11vw;
    opacity: 0;
  }
}
.confetti-30 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: 15px;
  opacity: 0;
  transform: rotate(243.2978294884deg);
  animation: drop-30 3.6809397923s 0.8243022935s 3;
}

@keyframes drop-30 {
  25% {
    top: -21vh;
    left: 9vw;
    opacity: 1.3868359217;
  }
  100% {
    top: 50vh;
    left: 24vw;
    opacity: 0;
  }
}
.confetti-31 {
  width: 4px;
  height: 1.6px;
  background-color: #0483f2;
  top: 0px;
  left: 1px;
  opacity: 0;
  transform: rotate(34.7381400737deg);
  animation: drop-31 3.8691664058s 0.8973773883s 3;
}

@keyframes drop-31 {
  25% {
    top: -23vh;
    left: -13vw;
    opacity: 1.2029314471;
  }
  100% {
    top: 50vh;
    left: -26vw;
    opacity: 0;
  }
}
.confetti-32 {
  width: 8px;
  height: 3.2px;
  background-color: #42f212;
  top: 0px;
  left: -12px;
  opacity: 0;
  transform: rotate(335.1771999697deg);
  animation: drop-32 3.267639962s 0.292338714s 3;
}

@keyframes drop-32 {
  25% {
    top: -10vh;
    left: 0vw;
    opacity: 1.2096693122;
  }
  100% {
    top: 50vh;
    left: 15vw;
    opacity: 0;
  }
}
.confetti-33 {
  width: 6px;
  height: 2.4px;
  background-color: #42f212;
  top: 0px;
  left: 14px;
  opacity: 0;
  transform: rotate(296.0825489309deg);
  animation: drop-33 3.8018200317s 0.2974672708s 3;
}

@keyframes drop-33 {
  25% {
    top: -10vh;
    left: -7vw;
    opacity: 0.770162236;
  }
  100% {
    top: 50vh;
    left: -6vw;
    opacity: 0;
  }
}
.confetti-34 {
  width: 2px;
  height: 0.8px;
  background-color: #42f212;
  top: 0px;
  left: 14px;
  opacity: 0;
  transform: rotate(42.5481895884deg);
  animation: drop-34 3.4205672162s 0.1257201833s 3;
}

@keyframes drop-34 {
  25% {
    top: -3vh;
    left: -9vw;
    opacity: 1.2496215816;
  }
  100% {
    top: 50vh;
    left: 4vw;
    opacity: 0;
  }
}
.confetti-35 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: 4px;
  opacity: 0;
  transform: rotate(92.0501580993deg);
  animation: drop-35 3.7014819872s 0.5105599688s 3;
}

@keyframes drop-35 {
  25% {
    top: -7vh;
    left: -4vw;
    opacity: 1.013544633;
  }
  100% {
    top: 50vh;
    left: 9vw;
    opacity: 0;
  }
}
.confetti-36 {
  width: 3px;
  height: 1.2px;
  background-color: #42f212;
  top: 0px;
  left: 11px;
  opacity: 0;
  transform: rotate(206.7908234903deg);
  animation: drop-36 3.8272035842s 0.3224907006s 3;
}

@keyframes drop-36 {
  25% {
    top: -19vh;
    left: 1vw;
    opacity: 1.0447430353;
  }
  100% {
    top: 50vh;
    left: -6vw;
    opacity: 0;
  }
}
.confetti-37 {
  width: 2px;
  height: 0.8px;
  background-color: #42f212;
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(356.5174743531deg);
  animation: drop-37 3.7651491849s 0.6886851258s 3;
}

@keyframes drop-37 {
  25% {
    top: -7vh;
    left: 4vw;
    opacity: 0.7707134025;
  }
  100% {
    top: 50vh;
    left: 17vw;
    opacity: 0;
  }
}
.confetti-38 {
  width: 7px;
  height: 2.8px;
  background-color: #0483f2;
  top: 0px;
  left: -5px;
  opacity: 0;
  transform: rotate(116.0789674529deg);
  animation: drop-38 3.860329242s 0.5985490193s 3;
}

@keyframes drop-38 {
  25% {
    top: -5vh;
    left: -5vw;
    opacity: 0.6524057704;
  }
  100% {
    top: 50vh;
    left: -18vw;
    opacity: 0;
  }
}
.confetti-39 {
  width: 7px;
  height: 2.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 7px;
  opacity: 0;
  transform: rotate(241.3063041915deg);
  animation: drop-39 3.1977689485s 0.0360652879s 3;
}

@keyframes drop-39 {
  25% {
    top: -19vh;
    left: -10vw;
    opacity: 0.5773935973;
  }
  100% {
    top: 50vh;
    left: -20vw;
    opacity: 0;
  }
}
.confetti-40 {
  width: 6px;
  height: 2.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -13px;
  opacity: 0;
  transform: rotate(121.7798583663deg);
  animation: drop-40 3.5655841592s 0.9794003055s 3;
}

@keyframes drop-40 {
  25% {
    top: -3vh;
    left: 15vw;
    opacity: 0.7107096698;
  }
  100% {
    top: 50vh;
    left: 5vw;
    opacity: 0;
  }
}
.confetti-41 {
  width: 6px;
  height: 2.4px;
  background-color: #42f212;
  top: 0px;
  left: -7px;
  opacity: 0;
  transform: rotate(186.0605442574deg);
  animation: drop-41 3.1804443096s 0.4897961426s 3;
}

@keyframes drop-41 {
  25% {
    top: -2vh;
    left: 13vw;
    opacity: 1.1711512054;
  }
  100% {
    top: 50vh;
    left: 6vw;
    opacity: 0;
  }
}
.confetti-42 {
  width: 4px;
  height: 1.6px;
  background-color: #42f212;
  top: 0px;
  left: 3px;
  opacity: 0;
  transform: rotate(186.3571828329deg);
  animation: drop-42 3.3964483085s 0.9403635344s 3;
}

@keyframes drop-42 {
  25% {
    top: -1vh;
    left: -10vw;
    opacity: 0.8409158584;
  }
  100% {
    top: 50vh;
    left: -10vw;
    opacity: 0;
  }
}
.confetti-43 {
  width: 4px;
  height: 1.6px;
  background-color: #0483f2;
  top: 0px;
  left: -13px;
  opacity: 0;
  transform: rotate(173.6030332448deg);
  animation: drop-43 3.5042966505s 0.1551672061s 3;
}

@keyframes drop-43 {
  25% {
    top: -25vh;
    left: -8vw;
    opacity: 0.5665793204;
  }
  100% {
    top: 50vh;
    left: -1vw;
    opacity: 0;
  }
}
.confetti-44 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: 2px;
  opacity: 0;
  transform: rotate(129.7338600441deg);
  animation: drop-44 3.0232430506s 0.4799434112s 3;
}

@keyframes drop-44 {
  25% {
    top: -16vh;
    left: 2vw;
    opacity: 0.6855982547;
  }
  100% {
    top: 50vh;
    left: -4vw;
    opacity: 0;
  }
}
.confetti-45 {
  width: 3px;
  height: 1.2px;
  background-color: #0483f2;
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(203.8455242733deg);
  animation: drop-45 3.3188578415s 0.2930846858s 3;
}

@keyframes drop-45 {
  25% {
    top: -18vh;
    left: 9vw;
    opacity: 1.4065175798;
  }
  100% {
    top: 50vh;
    left: -2vw;
    opacity: 0;
  }
}
.confetti-46 {
  width: 4px;
  height: 1.6px;
  background-color: #42f212;
  top: 0px;
  left: -4px;
  opacity: 0;
  transform: rotate(341.1291239127deg);
  animation: drop-46 3.7243441479s 0.2538160137s 3;
}

@keyframes drop-46 {
  25% {
    top: -20vh;
    left: -2vw;
    opacity: 1.3846122388;
  }
  100% {
    top: 50vh;
    left: 10vw;
    opacity: 0;
  }
}
.confetti-47 {
  width: 3px;
  height: 1.2px;
  background-color: #42f212;
  top: 0px;
  left: -6px;
  opacity: 0;
  transform: rotate(48.1248068319deg);
  animation: drop-47 3.4743510615s 0.2570316915s 3;
}

@keyframes drop-47 {
  25% {
    top: -19vh;
    left: -14vw;
    opacity: 0.7251903417;
  }
  100% {
    top: 50vh;
    left: -27vw;
    opacity: 0;
  }
}
.confetti-48 {
  width: 8px;
  height: 3.2px;
  background-color: #42f212;
  top: 0px;
  left: 2px;
  opacity: 0;
  transform: rotate(123.2431098142deg);
  animation: drop-48 3.8641136886s 0.9912845452s 3;
}

@keyframes drop-48 {
  25% {
    top: -7vh;
    left: -8vw;
    opacity: 0.6320077588;
  }
  100% {
    top: 50vh;
    left: -20vw;
    opacity: 0;
  }
}
.confetti-49 {
  width: 3px;
  height: 1.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -14px;
  opacity: 0;
  transform: rotate(67.2542553173deg);
  animation: drop-49 3.378914631s 0.1825600119s 3;
}

@keyframes drop-49 {
  25% {
    top: -20vh;
    left: 2vw;
    opacity: 1.0472188949;
  }
  100% {
    top: 50vh;
    left: 9vw;
    opacity: 0;
  }
}
.confetti-50 {
  width: 3px;
  height: 1.2px;
  background-color: #42f212;
  top: 0px;
  left: 8px;
  opacity: 0;
  transform: rotate(152.7508591434deg);
  animation: drop-50 3.1097329624s 0.1713066526s 3;
}

@keyframes drop-50 {
  25% {
    top: -5vh;
    left: -4vw;
    opacity: 0.6029609956;
  }
  100% {
    top: 50vh;
    left: -18vw;
    opacity: 0;
  }
}
.confetti-51 {
  width: 5px;
  height: 2px;
  background-color: #42f212;
  top: 0px;
  left: 4px;
  opacity: 0;
  transform: rotate(220.4673776952deg);
  animation: drop-51 3.5142845543s 0.7637342051s 3;
}

@keyframes drop-51 {
  25% {
    top: -10vh;
    left: 10vw;
    opacity: 0.865456337;
  }
  100% {
    top: 50vh;
    left: 19vw;
    opacity: 0;
  }
}
.confetti-52 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: -2px;
  opacity: 0;
  transform: rotate(178.747597147deg);
  animation: drop-52 3.4685514443s 0.1992134126s 3;
}

@keyframes drop-52 {
  25% {
    top: -1vh;
    left: 14vw;
    opacity: 1.2540266705;
  }
  100% {
    top: 50vh;
    left: 5vw;
    opacity: 0;
  }
}
.confetti-53 {
  width: 1px;
  height: 0.4px;
  background-color: #42f212;
  top: 0px;
  left: 7px;
  opacity: 0;
  transform: rotate(274.1470055856deg);
  animation: drop-53 3.3659001783s 0.7170497369s 3;
}

@keyframes drop-53 {
  25% {
    top: -24vh;
    left: -3vw;
    opacity: 1.3197766988;
  }
  100% {
    top: 50vh;
    left: -17vw;
    opacity: 0;
  }
}
.confetti-54 {
  width: 8px;
  height: 3.2px;
  background-color: #42f212;
  top: 0px;
  left: -11px;
  opacity: 0;
  transform: rotate(144.0125791949deg);
  animation: drop-54 3.6077989194s 0.9146320931s 3;
}

@keyframes drop-54 {
  25% {
    top: -5vh;
    left: 5vw;
    opacity: 0.584184946;
  }
  100% {
    top: 50vh;
    left: -3vw;
    opacity: 0;
  }
}
.confetti-55 {
  width: 2px;
  height: 0.8px;
  background-color: #42f212;
  top: 0px;
  left: -1px;
  opacity: 0;
  transform: rotate(143.1023428233deg);
  animation: drop-55 3.6154184998s 0.0850788269s 3;
}

@keyframes drop-55 {
  25% {
    top: -19vh;
    left: -5vw;
    opacity: 0.838902694;
  }
  100% {
    top: 50vh;
    left: -6vw;
    opacity: 0;
  }
}
.confetti-56 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 1px;
  opacity: 0;
  transform: rotate(279.6038500124deg);
  animation: drop-56 3.870010211s 0.3691315977s 3;
}

@keyframes drop-56 {
  25% {
    top: -18vh;
    left: -6vw;
    opacity: 0.8306189383;
  }
  100% {
    top: 50vh;
    left: -11vw;
    opacity: 0;
  }
}
.confetti-57 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: -10px;
  opacity: 0;
  transform: rotate(135.0203214655deg);
  animation: drop-57 3.3095846214s 0.4560282659s 3;
}

@keyframes drop-57 {
  25% {
    top: -5vh;
    left: -4vw;
    opacity: 0.5030795917;
  }
  100% {
    top: 50vh;
    left: -10vw;
    opacity: 0;
  }
}
.confetti-58 {
  width: 3px;
  height: 1.2px;
  background-color: #0483f2;
  top: 0px;
  left: 11px;
  opacity: 0;
  transform: rotate(110.9894818386deg);
  animation: drop-58 3.015075632s 0.9412252595s 3;
}

@keyframes drop-58 {
  25% {
    top: -12vh;
    left: 13vw;
    opacity: 1.1214307581;
  }
  100% {
    top: 50vh;
    left: 9vw;
    opacity: 0;
  }
}
.confetti-59 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: -1px;
  opacity: 0;
  transform: rotate(254.9886174476deg);
  animation: drop-59 3.7441194648s 0.8888026367s 3;
}

@keyframes drop-59 {
  25% {
    top: -24vh;
    left: -14vw;
    opacity: 1.2954199479;
  }
  100% {
    top: 50vh;
    left: -11vw;
    opacity: 0;
  }
}
.confetti-60 {
  width: 6px;
  height: 2.4px;
  background-color: #42f212;
  top: 0px;
  left: 4px;
  opacity: 0;
  transform: rotate(319.0217808272deg);
  animation: drop-60 3.1491468372s 0.7350482311s 3;
}

@keyframes drop-60 {
  25% {
    top: -25vh;
    left: -5vw;
    opacity: 0.9138337218;
  }
  100% {
    top: 50vh;
    left: -7vw;
    opacity: 0;
  }
}
.confetti-61 {
  width: 7px;
  height: 2.8px;
  background-color: #0483f2;
  top: 0px;
  left: -5px;
  opacity: 0;
  transform: rotate(120.2619142015deg);
  animation: drop-61 3.5021429784s 0.5767692657s 3;
}

@keyframes drop-61 {
  25% {
    top: -10vh;
    left: -10vw;
    opacity: 1.1484304492;
  }
  100% {
    top: 50vh;
    left: 1vw;
    opacity: 0;
  }
}
.confetti-62 {
  width: 7px;
  height: 2.8px;
  background-color: #0483f2;
  top: 0px;
  left: 5px;
  opacity: 0;
  transform: rotate(335.261693103deg);
  animation: drop-62 3.8718587871s 0.8844712764s 3;
}

@keyframes drop-62 {
  25% {
    top: -3vh;
    left: 14vw;
    opacity: 0.5057209608;
  }
  100% {
    top: 50vh;
    left: 19vw;
    opacity: 0;
  }
}
.confetti-63 {
  width: 1px;
  height: 0.4px;
  background-color: #42f212;
  top: 0px;
  left: 5px;
  opacity: 0;
  transform: rotate(9.1683726679deg);
  animation: drop-63 3.988820001s 0.9287546977s 3;
}

@keyframes drop-63 {
  25% {
    top: -10vh;
    left: 3vw;
    opacity: 0.6190378565;
  }
  100% {
    top: 50vh;
    left: -2vw;
    opacity: 0;
  }
}
.confetti-64 {
  width: 4px;
  height: 1.6px;
  background-color: #0483f2;
  top: 0px;
  left: 11px;
  opacity: 0;
  transform: rotate(139.7502251085deg);
  animation: drop-64 3.831577709s 0.8964779763s 3;
}

@keyframes drop-64 {
  25% {
    top: -19vh;
    left: -6vw;
    opacity: 1.2666838416;
  }
  100% {
    top: 50vh;
    left: 0vw;
    opacity: 0;
  }
}
.confetti-65 {
  width: 8px;
  height: 3.2px;
  background-color: #42f212;
  top: 0px;
  left: -3px;
  opacity: 0;
  transform: rotate(270.7247945011deg);
  animation: drop-65 3.0327366197s 0.8986112098s 3;
}

@keyframes drop-65 {
  25% {
    top: -17vh;
    left: 5vw;
    opacity: 1.1192914935;
  }
  100% {
    top: 50vh;
    left: 1vw;
    opacity: 0;
  }
}
.confetti-66 {
  width: 3px;
  height: 1.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -10px;
  opacity: 0;
  transform: rotate(358.7246218817deg);
  animation: drop-66 3.2913269534s 0.587201612s 3;
}

@keyframes drop-66 {
  25% {
    top: -14vh;
    left: -3vw;
    opacity: 0.9465945482;
  }
  100% {
    top: 50vh;
    left: 3vw;
    opacity: 0;
  }
}
.confetti-67 {
  width: 2px;
  height: 0.8px;
  background-color: #42f212;
  top: 0px;
  left: 8px;
  opacity: 0;
  transform: rotate(344.3342386051deg);
  animation: drop-67 3.9183085725s 0.8905739767s 3;
}

@keyframes drop-67 {
  25% {
    top: -6vh;
    left: -2vw;
    opacity: 0.5027664095;
  }
  100% {
    top: 50vh;
    left: 3vw;
    opacity: 0;
  }
}
.confetti-68 {
  width: 8px;
  height: 3.2px;
  background-color: #42f212;
  top: 0px;
  left: 11px;
  opacity: 0;
  transform: rotate(210.3597846905deg);
  animation: drop-68 3.9976563086s 0.3485986236s 3;
}

@keyframes drop-68 {
  25% {
    top: -23vh;
    left: -7vw;
    opacity: 0.963432626;
  }
  100% {
    top: 50vh;
    left: -3vw;
    opacity: 0;
  }
}
.confetti-69 {
  width: 3px;
  height: 1.2px;
  background-color: #0483f2;
  top: 0px;
  left: -5px;
  opacity: 0;
  transform: rotate(128.5875828077deg);
  animation: drop-69 3.4889596059s 0.4082969794s 3;
}

@keyframes drop-69 {
  25% {
    top: -1vh;
    left: 3vw;
    opacity: 0.8005972916;
  }
  100% {
    top: 50vh;
    left: 8vw;
    opacity: 0;
  }
}
.confetti-70 {
  width: 7px;
  height: 2.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -1px;
  opacity: 0;
  transform: rotate(179.9910599102deg);
  animation: drop-70 3.5020459349s 0.5937622075s 3;
}

@keyframes drop-70 {
  25% {
    top: -3vh;
    left: 14vw;
    opacity: 1.0835379956;
  }
  100% {
    top: 50vh;
    left: 3vw;
    opacity: 0;
  }
}
.confetti-71 {
  width: 7px;
  height: 2.8px;
  background-color: #0483f2;
  top: 0px;
  left: -8px;
  opacity: 0;
  transform: rotate(159.8233775298deg);
  animation: drop-71 3.2860854925s 0.1411653891s 3;
}

@keyframes drop-71 {
  25% {
    top: -1vh;
    left: 6vw;
    opacity: 1.0892766992;
  }
  100% {
    top: 50vh;
    left: 21vw;
    opacity: 0;
  }
}
.confetti-72 {
  width: 1px;
  height: 0.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -11px;
  opacity: 0;
  transform: rotate(230.4655582256deg);
  animation: drop-72 3.313981274s 0.20198676s 3;
}

@keyframes drop-72 {
  25% {
    top: -8vh;
    left: -14vw;
    opacity: 1.3655238847;
  }
  100% {
    top: 50vh;
    left: 1vw;
    opacity: 0;
  }
}
.confetti-73 {
  width: 1px;
  height: 0.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 3px;
  opacity: 0;
  transform: rotate(43.89042271deg);
  animation: drop-73 3.5642767084s 0.2888591023s 3;
}

@keyframes drop-73 {
  25% {
    top: -1vh;
    left: -9vw;
    opacity: 0.9994496479;
  }
  100% {
    top: 50vh;
    left: -10vw;
    opacity: 0;
  }
}
.confetti-74 {
  width: 6px;
  height: 2.4px;
  background-color: #42f212;
  top: 0px;
  left: 15px;
  opacity: 0;
  transform: rotate(286.5330981244deg);
  animation: drop-74 3.1673730729s 0.9298065971s 3;
}

@keyframes drop-74 {
  25% {
    top: -9vh;
    left: -8vw;
    opacity: 0.6884013859;
  }
  100% {
    top: 50vh;
    left: -13vw;
    opacity: 0;
  }
}
.confetti-75 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: -6px;
  opacity: 0;
  transform: rotate(81.4478467845deg);
  animation: drop-75 3.6628777799s 0.0261530387s 3;
}

@keyframes drop-75 {
  25% {
    top: -20vh;
    left: 3vw;
    opacity: 0.9176973359;
  }
  100% {
    top: 50vh;
    left: 7vw;
    opacity: 0;
  }
}

